import { Box, Stack, StackDivider } from '@chakra-ui/react'
import * as React from 'react'
import Stat from './Stat'
import { useStaticQuery, graphql } from 'gatsby'

export default function Customer() {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            accConnected
            compUsing
            connectivity
            siteUrl
          }
        }
      }
    `
  )
  const accountConnected = site.siteMetadata?.accConnected;
  const compUsing = site.siteMetadata?.compUsing;
  const connectivity = site.siteMetadata?.connectivity;
  return (
    <Box
      as="section"
      maxW="7xl"
      mx="auto"
      px={{ base: '6', md: '8' }}
      py={{ base: '12', md: '20' }}
      bg={'#3C3D35'}
    >
      <Stack spacing="8" direction={{ base: 'column', md: 'row' }} divider={<StackDivider />}>
        <Stat title="accounts connected" value={accountConnected ? accountConnected : "0"} />
        <Stat title="companies using our service" value={compUsing ? compUsing : "0"} />
        <Stat title="offices around the world" value={connectivity ? connectivity : "0"} />
      </Stack>
    </Box>
  );
}